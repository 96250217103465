import { Link } from 'react-router-dom'
import  thumbImg from "../images/thumb.png";
import  cardImg from "../images/card.png";
import  aboutUsImg from "../images/about_us.png";
import  game1Img from "../images/game1.png";
import  game2Img from "../images/game2.png";
import  game3Img from "../images/game3.png";
import  shape2Img from "../images/shape2.png";
import EventCard from "./EventCard";
import Faqs from './Faqs';
import Blogs from './Blogs';
function Main() {
  return (
    <>
 <section className="banner-area">
  <div className="container">
    <div className="b_cnt_wrapper">
      <div className="b_left_cnt">
        <div className="shape-1">
          <img src={cardImg} alt="karta" />
        </div>
        <h1>
          NAJLEPSZA DARMOWA GRA <span>KASYNO SPOŁECZNOŚCIOWE</span> Graj dziś.
        </h1>
        <p>
          GoldenSpinPL.com - Twoje darmowe i rozrywkowe kasyno społecznościowe
        </p>
        <div className="b_btn_area">
          <div className="h-btn">
            <Link to="/">
              <i className="fa-regular fa-circle-play"></i>
              Graj teraz
            </Link>
          </div>
          <div className="h-btn">
            <Link to="/">Zarejestruj się</Link>
          </div>
        </div>
      </div>
      <div className="b_right_cnt">
        <img src={thumbImg} alt="kciuk" />
      </div>
    </div>
  </div>
</section>
<section className="about-us-area">
  <div className="container">
    <div className="about_cnt_wrapper">
      <div className="shape-2">
        <img src={cardImg} alt="karta" />
      </div>
      <div className="about_right_cnt">
        <div className="about_left_img">
          <img src={aboutUsImg} alt="o nas" />
        </div>
        <h2>O kasynie</h2>
        <p>
          Kasyno to miejsce, w którym odbywają się różne gry hazardowe.
          Kasyna są często zlokalizowane w pobliżu hoteli, kurortów,
          restauracji, sklepów detalicznych, statków wycieczkowych i innych
          atrakcji turystycznych. Niektóre kasyna są również znane z
          organizowania rozrywek na żywo, takich jak stand-up, koncerty czy
          wydarzenia sportowe.
        </p>
        <div className="h-btn">
          <Link to="/">Skontaktuj się z nami</Link>
        </div>
      </div>
      <div className="about_left_cnt">
        <img src={aboutUsImg} alt="o nas" />
      </div>
    </div>
  </div>
</section>
<section className="top_upcoming-_event">
  <div className="container">
    <div className="section-title">
      <h2>Najlepsze gry</h2>
      <p>
        Doświadcz emocji gier kasynowych w czasie rzeczywistym dzięki funkcji
        transmisji na żywo! Graj z prawdziwymi krupierami, wchodź w interakcje
        z innymi graczami i ciesz się niesamowitym doświadczeniem kasyna w
        dowolnym miejscu i czasie.
      </p>
    </div>
    <div className="event_wrapper">
      <EventCard
        to={"/bigmax"}
        image={game1Img}
        title={"Gra Bigmax Diamonds and Wilds"}
        description={
          "Przeżyj ekscytację Bigmax Diamonds and Wilds! Zakręć bębnami, aby zdobyć lśniące diamenty i symbole wild, oraz wygrywaj wielkie nagrody z bonusami, darmowymi spinami i jackpotami."
        }
      />
      <EventCard
        to={"/king"}
        image={game2Img}
        title={"Król Egiptu"}
        description={
          "Zanurz się w starożytnym świecie Egiptu z grą Król Egiptu! Odkrywaj zagubione skarby, wygrywaj wielkie nagrody i doświadczaj tajemnic faraonów."
        }
      />
      <EventCard
        to={"/fish"}
        image={game3Img}
        title={"Gra Wielka Ryba"}
        description={
          "Zanurz się w głębinach z grą Wielka Ryba! Ciesz się różnorodnością gier, takich jak automaty, poker czy blackjack. Graj ze znajomymi i zdobywaj nagrody!"
        }
      />
    </div>
  </div>
</section>
<section className="why_play_area">
  <div className="container">
    <div className="why_cnt_wrapper">
      <div className="why_cnt_left">
        <h2>Dlaczego warto grać w naszym kasynie?</h2>
        <p>
          Kasyno to miejsce, w którym odbywają się różne gry hazardowe.
          Kasyna są często połączone z hotelami i kurortami.
        </p>
        <p>
          <span>
            Debitis ad dolor sint consequatur hic, facere est doloribus tempora
            in laborum similique saepe blanditiis odio nulla repellat dicta
            reprehenderit. Obcaecati, sed perferendis? Quam cum debitis odit
            recusandae dolor earum.
          </span>
        </p>
      </div>
      <div className="why_cnt_right">
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-shield-halved"></i>
          <h3>Bezpieczne gry kasynowe</h3>
          <p>
            Gry kasynowe to popularne formy hazardu. Istnieje wiele opcji w
            grach kasynowych.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-dice-six"></i>
          <h3>Świetne doświadczenie</h3>
          <p>
            Gry kasynowe to popularne formy hazardu. Istnieje wiele opcji w
            grach kasynowych.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-trophy"></i>
          <h3>Większe szanse na wygraną</h3>
          <p>
            Gry kasynowe to popularne formy hazardu. Istnieje wiele opcji w
            grach kasynowych.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-coins"></i>
          <h3>Inwestuj, wygrywaj, zarabiaj</h3>
          <p>
            Gry kasynowe to popularne formy hazardu. Istnieje wiele opcji w
            grach kasynowych.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="shape-3">
    <img src={shape2Img} alt="kształt" />
  </div>
</section>

      <Faqs/>
      <Blogs/>
   </>
  )
}

export default Main
