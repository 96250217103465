import { Link } from "react-router-dom";
import game1BackImg from "../images/game1-back.webp";
import game2BackImg from "../images/game2-back.webp";
import game3BackImg from "../images/game3-back.webp";

const blogsData = [
  {
    title: (
      <>
        Kasyno na Żywo
        <br /> Gra
      </>
    ),
    image: game1BackImg,
    description:
      "Poczuj atmosferę prawdziwego kasyna dzięki transmisji na żywo! Graj w czasie rzeczywistym z prawdziwymi krupierami, uczestnicz w interaktywnych grach i ciesz się autentycznym doświadczeniem kasyna w dowolnym miejscu.",
  },
  {
    title: (
      <>
        Wiadomości
        <br /> o Grach Kasynowych
      </>
    ),
    image: game2BackImg,
    description:
      "Bądź na bieżąco z najnowszymi grami kasynowymi! Czytaj wiadomości o nowych wydaniach gier, strategiach, trendach i ekskluzywnych aktualizacjach, aby być krok przed innymi i znaleźć najlepsze możliwości.",
  },
  {
    title: (
      <>
        Turnieje
        <br /> Gier Kasynowych
      </>
    ),
    image: game3BackImg,
    description:
      "Dołącz do ekscytujących turniejów gier kasynowych i rywalizuj z graczami z całego świata! Sprawdź swoje umiejętności w pokerze, blackjacku, automatach i innych grach podczas codziennych i sezonowych wydarzeń.",
  },
];

const Blogs = () => {
  return (
    <>
      <section className="blog_area">
        <div className="container">
          <div className="blog_title">
            <h2>
              WITAJ W ŚWIECIE
              <br />
              GIER KASYNOWYCH.
            </h2>
          </div>
          <div className="blog_cnt_wrapper">
            {blogsData.map(({ title, image, description }, index) => (
              <div key={index} className="blog_cnt">
                <div className="blog_img">
                  <img src={image} alt={title} />
                </div>
                <div className="blog_cnt_inner">
                  <h3>
                    <Link to={"/"}>{title}</Link>
                  </h3>
                  <p>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
