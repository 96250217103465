import { Link } from 'react-router-dom';
import redesignImg from '../images/redesign.webp';

function Footer() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="f-inner">
          <div className="f-left">
            <Link to="/">
              <img src={redesignImg} alt="redesign" />
            </Link>
            <p>
              Oświadczenie:
              Przygotuj się na zanurzenie w świat ekscytujących gier stworzonych
              wyłącznie dla Twojej rozrywki. GoldenSpinPL oferuje darmową
              platformę społecznościowego kasyna online, gdzie wszystkie funkcje
              gier i nagrody są wirtualne i nie mają rzeczywistej wartości pieniężnej.
              Oznacza to, że możesz cieszyć się emocjami związanymi z grami kasynowymi
              bez ryzyka utraty prawdziwych pieniędzy. Proszę pamiętać, że
              GoldenSpinPL jest przeznaczony dla osób powyżej 19 roku życia.
              Korzystając z naszej platformy, potwierdzasz, że spełniasz ten wymóg wiekowy.
            </p>
          </div>
          <div className="f-r">
            <div className="f-r-title">
              <h3>Informacje kontaktowe</h3>
            </div>
            <div className="f-r-btm">
              <p>
                <span>Adres:</span>
              </p>
              <p>
                Jan Kowalski ul. Kręta 5/3 00-486 Warszawa POLSKA
              </p>
            </div>
            <div className="f-r-btm">
              <p>
                <span>Telefon:</span>
              </p>
              <Link to="/">+48 22 621 10 31</Link>
            </div>
            <ul>
              <li>
                <Link to="/">Warunki korzystania</Link>
              </li>
              <li>
                <Link to="/">Polityka prywatności</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copy">
          <span>
            © 2021 GoldenSpinPL.com Stworzone przez{" "}
            <Link to="/">GoldenSpinPL.com</Link>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
