import shape2Img from "../images/shape2.png";

const faqItems = [
  {
    question: "01. Jak zacząć grać w gry kasynowe?",
    answer:
      "Zarejestruj konto, dokonaj wpłaty i wybierz preferowaną grę, aby rozpocząć grę.",
  },
  {
    question: "02. Czy mogę grać w darmowe gry kasynowe?",
    answer: "Tak, wiele kasyn oferuje darmowe wersje demonstracyjne gier.",
  },
  {
    question: "03. Czy granie w gry kasynowe jest bezpieczne?",
    answer:
      "Tak, jeśli grasz na licencjonowanych i regulowanych platformach kasynowych, twoje informacje i środki są bezpieczne.",
  },
  {
    question: "04. Jak mogę odebrać bonusy lub darmowe spiny?",
    answer:
      "Odbieraj bonusy, logując się na swoje konto i używając kodów promocyjnych lub stosując się do warunków kampanii.",
  },
  {
    question: "05. Jaka jest różnica między grami stołowymi a grami na automatach?",
    answer:
      "Gry kasynowe na żywo są rozgrywane w czasie rzeczywistym z prawdziwymi krupierami, podczas gdy zwykłe gry używają grafiki generowanej komputerowo.",
  },
  {
    question: "06. Czy mogę brać udział w turniejach gier kasynowych?",
    answer:
      "Tak, wiele kasyn oferuje turnieje, w których możesz rywalizować z innymi graczami o duże nagrody.",
  },
];

const Faqs = () => {
  return (
    <>
      <section className="faq_area">
        <div className="container">
          <div className="section-title">
            <h2>
              Często Zadawane
              <br /> Pytania
            </h2>
            <p>
              Kasyno to miejsce, w którym odbywają się różne gry hazardowe.
              Kasyna są często połączone z hotelami i kurortami.
            </p>
          </div>
          <div className="faq_cnt_wrapper">
            <div className="faq-section">
              {faqItems.slice(0, 3).map(({ question, answer }, index) => (
                <div key={index} className="faq-item">
                  <input type="checkbox" id={`question-${index}-a`} />
                  <label htmlFor={`question-${index}-a`}>{question}</label>
                  <div className="answer">
                    <p>{answer}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="faq-section">
              {faqItems.slice(3).map(({ question, answer }, index) => (
                <div key={index} className="faq-item">
                  <input type="checkbox" id={`question-${index}-b`} />
                  <label htmlFor={`question-${index}-b`}>{question}</label>
                  <div className="answer">
                    <p>{answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="shape-3">
          <img src={shape2Img} alt="kształt" />
        </div>
      </section>
    </>
  );
};

export default Faqs;
